import { graphql } from "gatsby";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  ArtistTag,
  ArtistTags,
  ArtistTagsContainer,
} from "../components/ArtistTags";
import Layout from "../components/Layout";
import MetaTags from "../components/MetaTags";
import ty from "../styled-tachyons";
import { DarkGraySection, ns, PageHeader, RichText } from "../styles";
import { Carousel } from "./Carousel";

const ArtistsPageStyle = styled.div``;

const ArtistsSection = styled(DarkGraySection)`
  ${RichText} {
    ${ty`center f5 tc`}
    ${ns("max-width: 56.25rem")}
  }

  & + ${DarkGraySection} {
    ${ty`pt0`}
  }
`;

const artistCardTitle = ({ ivr, artistName }) => {
  let cardTitle = artistName;
  if (ivr) {
    cardTitle = `${ivr} : ${cardTitle}`;
  }

  return cardTitle;
};

export const ArtistsPageTemplate = ({
  title,
  html,
  excerpt,
  artists = [],
  projects = [],
}) => (
  <ArtistsPageStyle>
    <MetaTags title={title} description={excerpt} />
    <ArtistsSection>
      <PageHeader
        css={`
          ${ty`tc`}
        `}
      >
        {title}
      </PageHeader>
      <RichText dangerouslySetInnerHTML={{ __html: html }} />
    </ArtistsSection>
    <DarkGraySection>
      <ArtistTagsContainer>
        <ArtistTags>
          {artists.map(({ name, link }, i) => {
            let target = link && link.indexOf("/") === 0 ? "" : "_blank";
            return (
              <ArtistTag
                key={i.toString() + name}
                href={link}
                target={target}
                title={name}
              >
                {name}
              </ArtistTag>
            );
          })}
        </ArtistTags>
      </ArtistTagsContainer>
    </DarkGraySection>
    <Carousel projects={projects} getCardTitle={artistCardTitle}></Carousel>
  </ArtistsPageStyle>
);

ArtistsPageTemplate.propTypes = {};

const ArtistsPage = (p) => {
  const { data } = p;
  const { frontmatter, html, excerpt } = data.markdownRemark;
  const { featuredProject } = frontmatter;
  const artistsNodes = data.allArtists.edges.slice();
  const projectNodes = data.allProjects.edges.slice().reverse();

  const artists = React.useMemo(() => {
    if (!artistsNodes) {
      return [];
    }

    return artistsNodes.map(({ node }) => {
      let artistProject = _.find(
        projectNodes,
        (p) => p.node?.fields?.artistNode?.id === node.id
      );
      //console.log({ artistProject });
      let link = artistProject
        ? artistProject.node.fields.slug
        : node.frontmatter.link;
      return {
        link,
        name: node.frontmatter.name,
      };
    });
  }, [artistsNodes, projectNodes]);

  const projects = React.useMemo(() => {
    if (!projectNodes) {
      return [];
    }

    let sorted = projectNodes;
    if (featuredProject) {
      let projectNode = _.find(sorted, {
        node: { frontmatter: { uuid: featuredProject } },
      });
      if (projectNode) {
        sorted = _.without(sorted, projectNode);
        sorted = [...sorted, projectNode];
      }
    }

    return sorted.map(({ node }) => {
      return {
        ...node.frontmatter,
        artistName: _.get(node, "fields.artistNode.frontmatter.name"),
        imageUrl: node.frontmatter.carouselImage || node.frontmatter.coverImage,
        projectUrl: _.get(node, "fields.slug"),
      };
    });
  }, [projectNodes, featuredProject]);

  return (
    <Layout>
      <ArtistsPageTemplate
        title={frontmatter.title}
        html={html}
        excerpt={excerpt}
        artists={artists}
        projects={projects}
      />
    </Layout>
  );
};

ArtistsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ArtistsPage;

export const pageQuery = graphql`
  query ArtistsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "artists-page" } }) {
      html
      excerpt
      frontmatter {
        title
        featuredProject
      }
    }

    allArtists: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sortOrder] }
      filter: { frontmatter: { entityType: { eq: "artist" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            link
          }
        }
      }
    }

    allProjects: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___startDate] }
      filter: { frontmatter: { entityType: { eq: "project" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
            artistNode {
              id
              frontmatter {
                name
              }
            }
          }
          frontmatter {
            uuid
            ivr
            title
            subTitle
            subSubTitle
            link
            artist

            coverImage {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            carouselImage {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
